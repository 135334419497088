import React from 'react'

import './index.css'

let myString =
  'Handmade - Unique designs - Made to order - Locally sourced materials - Adjustable fit - Versatile - Melbourne made - Handmade - Unique designs - Made to order - Locally sourced materials - Adjustable fit - Versatile - Melbourne made - Handmade - Unique designs - Made to order - Locally sourced materials - Adjustable fit - Versatile - Melbourne made - Handmade - Unique designs - Made to order - Locally sourced materials - Adjustable fit - Versatile - Melbourne made - Handmade - Unique designs - Made to order - Locally sourced materials - Adjustable fit - Versatile - Melbourne made - Handmade - Unique designs - Made to order - Locally sourced materials - Adjustable fit - Versatile - Melbourne made - '

export default () => {
  return (
    <div id='feature-text'>
      {myString.split('-').map((c, i) => (
        <span key={i}>{`${c} -`}</span>
      ))}
    </div>
  )
}
