import React, { useEffect, useState } from 'react'

import { db, auth } from './base'

export const FirebaseContext = React.createContext()

export const FirebaseProvider = ({ children }) => {
  const [items, setItems] = useState(null)
  const [currentUser, setCurrentUser] = useState(null)

  useEffect(() => {
    auth.onAuthStateChanged((resp) => {
      if (resp && ['alexanderj2396@gmail.com', 'jewelintimates@gmail.com'].includes(resp.email)) setCurrentUser(resp)
      else setCurrentUser(false)
    })
  }, [])

  useEffect(() => {
    db.ref('items').on('value', (resp) => resp.val() && setItems(Object.entries(resp.val())))
  }, [])

  return <FirebaseContext.Provider value={{ items, currentUser }}>{children}</FirebaseContext.Provider>
}
