import React from 'react'

export default ({ label, options, onSelect, selection, id }) => (
  <>
    <h3>{label}</h3>
    <select
      value={selection || 'none'}
      placeholder='Select a size'
      className='pdct-select'
      onChange={(e) => onSelect(e, id)}>
      <option disabled value='none'>
        Nothing selected yet
      </option>

      {options.map((o) => (
        <option key={o[1].label} value={o[1].label} disabled={o[1].stock && !o[1].stock > 0}>
          {o[1].label}
        </option>
      ))}
    </select>
  </>
)
