import React, { useContext, useEffect, useState } from 'react'
import { FirebaseContext } from '../../firebase/FirebaseContext'
import { CartContext } from '../../layout/Cart/CartContext'
import { useParams } from 'react-router-dom'

import Select from './components/Select'
import Stock from './components/Stock'
import Signup from '../Signup'

import './index.css'

export default () => {
  const { items } = useContext(FirebaseContext)
  const { addItem } = useContext(CartContext)

  let { id } = useParams()
  const [item, setItem] = useState(null)
  const [added, setAdded] = useState(false)

  const [photoIndex, setPhotoIndex] = useState(0)

  useEffect(() => {
    if (items && id) {
      let index = items.findIndex((r) => r[0] === id)
      setItem(items[index])
    }
    return () => setItem(null)
  }, [items, id])

  useEffect(() => {
    added && setTimeout(() => setAdded(false), 1500)
  }, [added])

  return (
    <>
      {item && item[1].remaining >= 0 && (
        <div className='pdct-con fadeIn'>
          <h1 className='pdct-title'>{item[1].name}</h1>
          <div>
            <img className='pdct-img' alt='product' src={item[1].photos[photoIndex]} />
            <div style={{ display: 'flex', marginLeft: -41 }}>
              <div
                className={`img-btn ${photoIndex === 0 && 'img-btn-inactive'}`}
                style={{ borderRight: 0 }}
                onClick={() => photoIndex > 0 && setPhotoIndex(photoIndex - 1)}>
                {'<'}
              </div>
              <div
                className={`img-btn ${item[1].photos.length - 1 === photoIndex && 'img-btn-inactive'}`}
                onClick={() => item[1].photos.length - 1 > photoIndex && setPhotoIndex(photoIndex + 1)}>
                {'>'}
              </div>
            </div>
          </div>
          <div className='pdct-option-con'>
            <div className='pdct-desc'>{item[1].description}</div>
            {item[1].options &&
              Object.entries(item[1].options).map((option) => (
                <Select
                  label={option[1].label}
                  options={Object.entries(option[1].options)}
                  key={option[0]}
                  id={option[0]}
                  onSelect={(e, id) => {
                    let _item = [...item]
                    _item[2] = { ..._item[2], [id]: e.target.value }
                    setItem(_item)
                  }}
                  selection={item[2] && item[2][option[0]]}
                />
              ))}
            <a href='\sizing.pdf' target='_blank'>
              <div className='pdct-size-guide'>Size guide</div>
            </a>
            <div className='pdct-notice'>Made to Order; Please allow 2/3 weeks.</div>
            <div className='pdct-price'>AUD ${item[1].cost.toFixed(2)}</div>
          </div>

          <Stock
            item={item}
            added={added}
            addToBag={() => {
              let allow = Object.entries(item[1].options).map((choice) => (item[2] && item[2][choice[0]]) || false)
              if (!allow.includes(false)) {
                setAdded(true)
                addItem({ [id]: item[2] })
              } else window.alert('Please select options')
            }}
          />
        </div>
      )}
      <Signup />
    </>
  )
}
