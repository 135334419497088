import React from 'react'
import { FirebaseProvider } from './components/firebase/FirebaseContext'
import { CartProvider } from './components/layout/Cart/CartContext'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import MyRoute from './components/MyRoute'
import AdminRoute from './components/AdminRoute'

import Home from './components/pages/Home'
import ShopAll from './components/pages/ShopAll'
import Product from './components/pages/Product'
import Signup from './components/pages/Signup'
import About from './components/pages/About'
import Contact from './components/pages/Contact'
import Thanks from './components/pages/Thanks'

import Login from './components/pages/Admin/Login'
import Dashboard from './components/pages/Admin/Dashboard'

import './App.css'

const App = () => (
  <FirebaseProvider>
    <CartProvider>
      <div className='App'>
        <Router>
          <Switch>
            <MyRoute exact path='/' component={Home} />
            <MyRoute path='/shop-all' component={ShopAll} />
            <MyRoute path='/product/:id' component={Product} />
            <MyRoute path='/about' component={About} />
            <MyRoute path='/contact' component={Contact} />
            <MyRoute path='/thanks' component={Thanks} />
            <Route path='/signup' component={Signup} />

            {/* Login */}
            <Route path='/admin/login' component={Login} />
            <AdminRoute path='/admin' component={Dashboard} />
          </Switch>
        </Router>
      </div>
    </CartProvider>
  </FirebaseProvider>
)

export default App
