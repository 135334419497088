import React from 'react'

export default () => {
  return (
    <div>
      <h3>ABOUT JEWEL INTIMATES</h3>
      Jewel Intimates is a small business created by Julia Albanese. Julia works hard to develop high end, hand made
      products that are created from beautiful fabrics. The entire process is done in studio from the initial
      designs/patterns all the way through to manufacturing of the garments. Each garment is truly designed and made
      with love here in Melbourne.
      <br /> With over 2 years’ experience working in a lingerie store and completing a uni degree specialising in
      lingerie, Julia has extensive knowledge of lingerie fabrics and fit. You will notice that there are often fit tips
      posted to our Instagram to help ensure you are fitting your bralette correct. All bralettes also come with
      adjustable straps to help achieve the perfect fit.
      <br />
      <h3>MADE TO ORDER</h3>
      All of our garments are currently made to order. This means we do not start making the garments until they have
      been ordered. This helps us ensure we are not contributing to an overloaded industry where garments end up in
      landfill and we are only making what is necessary. <br />
      Due to garments being made to order please allow 3-4 weeks + shipping. However, we will keep you updated and let
      you know how your order is coming along throughout the process.
      <h3>DELIVERY</h3>
      Standard Australia Post shipping Flat rate $10 We will notify you of your tracking number once your order is
      complete and ready to ship.
      <h3>RETURNS/EXCHANGES</h3>
      Unfortunately, due to hygiene reasons at this time we are unable to offer returns or exchanges. Please read our
      measurements guide carefully and contact us if you have any questions and we would be more than happy to help!
    </div>
  )
}
