import React, { useState, useContext, useEffect } from 'react'
import { FirebaseContext } from '../../firebase/FirebaseContext'

export const CartContext = React.createContext()
const Provider = CartContext.Provider

export const CartProvider = ({ children }) => {
  const { items } = useContext(FirebaseContext)
  const [bag, setBag] = useState([])

  // useEffect(() => {
  //   recover()
  // }, [])

  useEffect(() => {
    localStorage.setItem('items', JSON.stringify(bag))
  }, [bag])

  const addItem = (item) => {
    let itemIndex = items.findIndex((i) => i[0] === Object.keys(item)[0])
    let newItem = items[itemIndex]
    let itemInBag = bag.findIndex((i) => i[0] === Object.keys(item)[0])
    console.log(Object.values(item))

    if (itemInBag === -1) {
      newItem[1].quantity = [Object.values(item)[0]]
      setBag([newItem, ...bag])
    } else {
      let _bag = [...bag]
      _bag[itemInBag][1].quantity = [..._bag[itemInBag][1].quantity, Object.values(item)[0]]
      setBag(_bag)
    }
  }
  const removeItems = (item) => {
    let index = bag.indexOf(item)
    let _bag = [...bag]
    _bag.splice(index, 1)
    setBag(_bag)
  }

  const recover = () => {
    let local = localStorage.getItem('items')
    if (local) setBag(JSON.parse(local))
  }

  const clear = () => {
    setBag([])
    localStorage.clear()
  }

  return <Provider value={{ recover, clear, bag, addItem, removeItems }}>{children}</Provider>
}
