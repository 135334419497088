import React, { useContext, useState, useEffect } from 'react'
import { CartContext } from './CartContext'
import { useLocation } from 'react-router-dom'
import { HiOutlineShoppingBag } from 'react-icons/hi'
import { loadStripe } from '@stripe/stripe-js'

import './index.css'

const stripePromise = loadStripe(
  'pk_live_51HI05MKTG59BPWzZUe1wmm9BSed3o1gCD0omUvVJI3J5UGzuhlq54jZfWSTgE0FFg9nYf4g98KrTfsySKePHDETw00GB0gX866'
)

export default () => {
  const { bag, recover, clear, removeItems } = useContext(CartContext)
  const { pathname } = useLocation()

  const [isShown, setIsShown] = useState(false)
  const [message, setMessage] = useState('')
  const [redirected, setRedirected] = useState(false)

  let itemCount = 0
  bag.forEach((i) => i[1].quantity.forEach((o) => itemCount++))

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search)
    if (query.get('success')) {
      clear()
      setMessage('Order placed! You will receive an email confirmation.')
    }
    if (query.get('canceled')) {
      recover()
      setMessage("Order canceled -- continue to shop around and checkout when you're ready.")
    }
    //eslint-disable-next-line
  }, [])

  const handleClick = async (event) => {
    const stripe = await stripePromise

    const response = await fetch('https://us-central1-jewel-intimates.cloudfunctions.net/stripe/', {
      // const response = await fetch('http://localhost:5001/jewel-intimates/us-central1/stripe', {
      method: 'POST',
      body: JSON.stringify(bag)
    })
    let session = await response.json()
    console.log(session)

    const result = await stripe.redirectToCheckout({ sessionId: session.id })
    console.log(result)

    if (result.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    }
  }
  false && console.log(message)

  return (
    bag.length > 0 &&
    pathname !== '/checkout' && (
      <>
        <div id={isShown ? 'cart-invis' : ''} onClick={() => setIsShown(false)} />
        <div id='bag' onClick={() => setIsShown(true)} className={isShown ? 'bag-shown' : ''}>
          {isShown ? (
            <div id='bag-list'>
              {bag.map((item) => {
                let uniqueOptions = []
                item[1].quantity.forEach(
                  (i) =>
                    uniqueOptions.findIndex((r) => JSON.stringify(r) === JSON.stringify(i)) === -1 &&
                    uniqueOptions.push(i)
                )
                return (
                  <div key={item[0]} className='bag-item-con'>
                    <img src={item[1].imagePath} className='bag-item-image' alt='product' />

                    <div style={{ minWidth: 200 }}>
                      <div className='bag-item-name'>{item[1].name}</div>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {uniqueOptions.map((c, ind) => {
                          let count = 0

                          item[1].quantity.forEach((i) => JSON.stringify(i) === JSON.stringify(c) && count++)

                          return (
                            <div className='bag-item-quantity' key={ind}>
                              {`(${count} x ${Object.values(c)[0]})`}
                            </div>
                          )
                        })}
                        <div className='bag-item-cost'>A${(item[1].cost * item[1].quantity.length).toFixed(2)}</div>
                        <div className='clear-item' onClick={() => removeItems(item)}>
                          remove
                        </div>
                      </div>
                    </div>

                    {/* <div className='bag-item-quantity'>{item[1].quantity} x</div>
                     */}
                  </div>
                )
              })}
              <div
                id='checkout-btn'
                onClick={() => {
                  handleClick()
                  setRedirected(!redirected)
                }}
                className={redirected ? 'btn-loading' : 'btn-waiting'}>
                {redirected ? 'Being Redirected' : 'Checkout'}
              </div>
            </div>
          ) : (
            <div id='bag-icon-con'>
              <HiOutlineShoppingBag id='bag-icon' />
              <div style={{ marginLeft: 10 }}>
                {itemCount} {itemCount === 1 ? 'item' : 'items'}
              </div>
            </div>
          )}
        </div>
      </>
    )
  )
}
