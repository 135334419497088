import React from 'react'
import { NavLink, Link } from 'react-router-dom'

import './index.css'

export default () => (
  <div id='header'>
    <Link to='/'>
      <div id='logo'>Jewel Intimates</div>
    </Link>

    <div id='menu'>
      <NavLink to='/shop-all' exact activeClassName='menu-active'>
        Shop All
      </NavLink>
      <NavLink to='/about' activeClassName='menu-active'>
        About
      </NavLink>
      <a href='mailto:jewelintimates@gmail.com'>Contact</a>
    </div>
  </div>
)
