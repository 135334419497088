import React from 'react'
import { auth } from '../../firebase/base'
import css from './style.module.css'

export default () => {
  return (
    <div id={css.dashboard}>
      <div id={css.header}>
        Admin
        <span id={css.logout} onClick={() => auth.signOut()}>
          Sign out
        </span>
      </div>
      <div id={css.actions}>
        <input id={css.search} placeholder='Search...' />
        <div id={css.create}>Create New</div>
      </div>
      <div id={css.items}>
        <div className={css.item}>
          <div>
            <div className={css.title}>Title</div>
            <div className={css.photos}>
              <div className={css.photo}></div>
              <div className={css.photo}></div>
              <div className={css.photo}></div>
              <div className={css.photo}></div>
            </div>
            <div className={css.sizes}>S / M / L</div>
          </div>
          <div>
            <div className={css.description}>
              Description An essential longline bralette appropriate for everyday wear. Made from comfortable
              nylon/lycra stretch lace, this bralette is designed to be shown off. It features adjustable straps, soft
              cup lining and an extended bar back that offers optimal fit. Designed to be styled with a see-through
              shirt or under a blazer. Handmade with love in Melbourne.
            </div>
            <div className={css.price}>89.95</div>
          </div>
        </div>
      </div>
    </div>
  )
}
