import * as firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/storage'
import 'firebase/functions'
import 'firebase/analytics'
import 'firebase/auth'

const app = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
})

const db = app.database()
const stg = app.storage()
const func = app.functions()
app.analytics()
const auth = app.auth()

let ts = firebase.database.ServerValue.TIMESTAMP
let provider = new firebase.auth.GoogleAuthProvider()

export { db, stg, ts, func, auth, provider }
