import React, { useState, useContext } from 'react'
import { Redirect } from 'react-router-dom'
import { auth, provider } from '../../firebase/base'
import { FirebaseContext } from '../../firebase/FirebaseContext'

export default () => {
  const { currentUser } = useContext(FirebaseContext)
  const [loading, setLoading] = useState(false)

  if (currentUser) return <Redirect to='/admin/dashboard' />

  return (
    <div style={{ padding: '20px 50px' }}>
      <h1>Login</h1>
      <button
        disabled={loading}
        onClick={async () => {
          setLoading(true)
          await auth.signInWithPopup(provider)
          setLoading(false)
        }}>
        Sign in with google
      </button>
    </div>
  )
}
