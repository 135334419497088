import React from 'react'

import './index.css'

export default () => (
  <div id='footer'>
    <div id='footer-one'></div>
    <div id='footer-two'></div>
  </div>
)
