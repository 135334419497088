import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'

import { FirebaseContext } from './firebase/FirebaseContext'

export default ({ ...rest }) => {
  const { currentUser } = useContext(FirebaseContext)

  if (currentUser === null) return <div> Loading...</div>

  return currentUser ? <Route {...rest} /> : <Redirect to='/admin/login' />
}
