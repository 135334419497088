import React, { useContext, useEffect, useState } from 'react'
import { Route, Redirect, useLocation } from 'react-router-dom'

import { FirebaseContext } from './firebase/FirebaseContext'
import moment from 'moment'
import Countdown from 'react-countdown'

import Header from './layout/Header'
import Footer from './layout/Footer'
import FeatureText from './layout/FeatureText'
import Cart from './layout/Cart'

export default ({ ...rest }) => {
  const { items } = useContext(FirebaseContext)
  const { pathname } = useLocation()
  const [isComplete, setIsComplete] = useState(false)

  let date = '2020-10-14 18:30:00'

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    moment(date).diff(moment()) <= 0 && setIsComplete(true)
    //eslint-disable-next-line
  }, [])

  if (!isComplete) {
    return (
      <div id='countdown'>
        <h3>White Opal Collection Launching In:</h3>
        <Countdown date={moment(date).toDate()} onComplete={() => setIsComplete(true)} className='countdown-timer' />
      </div>
    )
  }

  return items !== false ? (
    <div>
      <FeatureText />
      <Header />
      <div id='content'>
        <Route {...rest} />
      </div>
      <Cart />
      <Footer />
    </div>
  ) : (
    <Redirect to='/signup' />
  )
}
