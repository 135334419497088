import React, { useState } from 'react'
import * as EmailValidator from 'email-validator'
import { db, ts } from '../../firebase/base'

import './index.css'

export default () => {
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState(null)

  const submit = async () => {
    setLoading(true)
    EmailValidator.validate(email)
      ? await db
          .ref('customers')
          .orderByChild('email')
          .equalTo(email)
          .once('value', (snap) => {
            snap.val()
              ? setStatus({ msg: 'Email already submitted.', type: 'error' })
              : db
                  .ref('customers')
                  .push({
                    email,
                    dateCreated: ts,
                    isSubscribed: true
                  })
                  .then(() => setStatus({ msg: 'Email submitted.', type: 'success' }))
          })
      : setStatus({ msg: 'Email address poorly formatted; please try again.', type: 'error' })
    setLoading(false)
  }

  return (
    <div className='sign-up-banner'>
      <h3>Launch Notifications</h3>
      {status ? (
        <div className={status.type === 'error' ? 'sign-up-error' : 'sign-up-success'}>{status.msg}</div>
      ) : (
        <code>Get notified when new items come out.</code>
      )}
      {((status && status.type !== 'success') || status === null) && (
        <div className='sign-up-input-con'>
          <input
            placeholder='janeSmith@gmail.com'
            className='sign-up-input'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type='email'
          />
          <button className='sign-up-button' onClick={() => submit()} disabled={loading}>
            Submit
          </button>
        </div>
      )}
    </div>
  )
}
