import React, { useContext } from 'react'
import { FirebaseContext } from '../../firebase/FirebaseContext'
import { Link } from 'react-router-dom'

export default () => {
  const { items } = useContext(FirebaseContext)

  return (
    items && (
      <>
        <h1 className='header-text' id='shop-all'>
          Shop All
        </h1>
        <div className='items'>
          {items.map((i) => (
            <Link key={i[0]} to={`product/${i[0]}`} className='link item-con fadeIn'>
              <div className='item-name'>{i[1].name}</div>
              <img
                className={`item-image ${i[1].remaining === 0 && 'sold-out-img'}`}
                alt='product'
                src={i[1].photos[0]}
              />
              {i[1].remaining === 0 ? (
                <div className='item-status sold-out'>Sold Out</div>
              ) : i[1].remaining === -1 ? (
                <div className='item-status sold-out'>Coming Soon</div>
              ) : (
                <div className='item-status in-stock'>
                  {i[1].remaining <= 3 ? `${i[1].remaining} Remaining` : 'In Stock'}
                </div>
              )}
            </Link>
          ))}
        </div>
      </>
    )
  )
}
