import React, { useContext } from 'react'
import { FirebaseContext } from '../../firebase/FirebaseContext'
import { Link } from 'react-router-dom'

import Signup from '../Signup'

import './index.css'

export default () => {
  const { items } = useContext(FirebaseContext)

  let recent = items && items.slice(items.length - 3)

  return (
    <div>
      <div id='hero'>
        <h2 style={{ marginLeft: 25 }}>Handmade Lingerie in Melbourne, Australia</h2>
        <img
          src='https://firebasestorage.googleapis.com/v0/b/jewel-intimates.appspot.com/o/SLIP%20FLAT%20LAY.jpg?alt=media&token=988d994b-314a-45c1-8ecb-61f0ca379770'
          alt='heroimg'
          id='hero-img'
        />
      </div>
      <h2 style={{ marginTop: 40, marginLeft: 25, marginBottom: 0 }}>Recent Releases</h2>
      <div className='items'>
        {recent &&
          recent.map((i) => (
            <Link key={i[0]} to={`product/${i[0]}`} className='link item-con fadeIn'>
              <div className='item-name'>{i[1].name}</div>
              <img
                className={`item-image ${i[1].remaining === 0 && 'sold-out-img'}`}
                alt='product'
                src={i[1].photos[0]}
              />
              {i[1].remaining === 0 ? (
                <div className='item-status sold-out'>Sold Out</div>
              ) : (
                <div className='item-status in-stock'>
                  {i[1].remaining <= 3 ? `${i[1].remaining} Remaining` : 'In Stock'}
                </div>
              )}
            </Link>
          ))}
      </div>
      <h5>Can't find what your looking for?</h5>
      <Link to={`shop-all`} className='link '>
        <h3>Shop All</h3>
      </Link>
      <Signup />
    </div>
  )
}
