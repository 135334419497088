import React from 'react'

export default () => (
  <div>
    <h2>Thank you!</h2>
    <div>Your purchase has been completed; you will receive an email receipt shortly.</div>
    <div style={{ marginTop: 20, fontStyle: 'italic', textDecoration: 'underline', cursor: 'pointer' }}>
      <a href='https://www.instagram.com/jewel___intimates/'>Check us out on Instagram.</a>
    </div>
  </div>
)
