import React from 'react'

export default ({ item, addToBag, added }) => {
  if (item[1].remaining === 0) {
    return (
      <div className='pdct-stock-con'>
        <div className='pdct-stock pdct-stock-out'> Sold Out</div>
      </div>
    )
  }
  if (item[1].remaining === -1) {
    return (
      <div className='pdct-stock-con'>
        <div className='pdct-stock pdct-stock-out'>Coming Soon</div>
      </div>
    )
  } else
    return (
      <div className='pdct-stock-con'>
        <div className='pdct-stock'> {item[1].remaining <= 3 ? `${item[1].remaining} Remaining` : 'In Stock'}</div>
        <code className={`pdct-cart ${added && 'colorful added-recently'}`} onClick={() => addToBag()}>
          {added ? 'Added to cart' : 'Add to cart'}
        </code>
      </div>
    )
}
